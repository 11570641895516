import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuditResponseRecord } from './AuditResponseRecord.model';
import { GenerateCode } from './GenerateCode.model';
import { LockUserAccess, LockUsersDetails } from './LockUserAccess.model';
import { ActiveLockUsersAndLocks } from './ActiveLockUsersAndLocks.model';
import { GetActiveLockUsersAndLocks } from './GetActiveLockUsersAndLocks.model';
import { LockUserGroup } from './LockUserGroup.model';
import { LockUserGroupMems } from './LockUserGroupMems.model';
import { LockUsers } from './LockUsers.model';
import { SendMessage } from './SendMessage.model';
import { UserGetResponseRecord } from './userGetResponseRecord.model';
import { UserLocksModel } from './UserLocksModel.model';
import * as jwt_decode from 'jwt-decode';
import { UserUpdateRequest } from './UserUpdateRequest.model';
import { LockUpdateRequest } from './LockUpdateRequest.model';
import { UserGetRequest } from './UserGetRequest.model';
import { LockGlobalAdmin } from './LockGlobalAdmin.model';
import { LockGetRequest } from './LockGetRequest.model';
import { AddUserToGroupRequest } from './AddUserToGroupRequest.model';
import { UserUpdatePasswordRequest } from './UserUpdatePasswordRequest.model';

// const usersURL = "/api/Get";
const usersURL = '/api/user/getUsers';
const getUserURL = '/api/user/GetUserDetails';
const userLocksURL = '/api/lock/getLocks';
const assignedUserLocksURL = '/api/lock/getAssignedLocks';
const updateLockURL = '/api/lock/putLock';
const deActivateLockURL = '/api/lock/DeActivateLock';
const addNewUserURL = '/api/user/addUser';
const deleteUserURL = '/api/user/deleteUser';
const updateUserURL = '/api/user/updateUser';
const createOwnerUserAndActivateLock = '/api/AutoOwnerUserActivateLock/createOwnerUserAndActivateLock';
const addNewLockUserURL = '/api/lockUser/createLockUser';
const updateLockUserURL = '/api/lockUser/updateLockUser';
const updateLockUsersURL = '/api/lockUser/updateLockUsers';
const deleteLockUserURL = '/api/lockUser/deleteLockUser';
const getActivationCodeURL = '/api/lock/getActivationCode';
const getLockUserAccessURL = '/api/lockuseraccess/GetLockUserAccess';
const getActiveLockUsersAndLocksURL = '/api/lockuseraccess/GetActiveLockUsersAndLocks';
const lockUsersURL = '/api/lockUser/getLockUsers';
const activateLockURL = '/api/activateLock/activate';
const activateLocksURL = '/api/activateLocks/activate';
const generateCodeURL = '/api/lockuseraccess/getLockCode';
const generateCodesURL = '/api/lockuseraccess/getLockCodes';
const removeCodeURL = '/api/lockuseraccess/removeUserLockAccess';
const removeCodesURL = '/api/lockuseraccess/removeUserLockAccesses';
const sendMessageURL = '/api/message/sendMessage';
const auditURL = '/api/audit/getAudit';
const downloadAudit = '/api/audit/downloadAudit';
const createLockUserGroupURL = '/api/lockUserGroup/addLockUserGroup';
const readLockUserGroupsURL = '/api/lockUserGroup/GetLockUserGroups';
const deleteLockUserGroupURL = '/api/lockUserGroup/deleteLockUserGroup';
const getLockUserGroupMemsURL = '/api/lockUserGroupMembers/getLockUserGroupMembers';
const addToGroupURL = '/api/lockUserGroupMembers/addLockUserGroupMember';
const deleteFromGroupURL = '/api/lockUserGroupMembers/deleteLockUserGroupMember';

@Injectable()
export class Repository {
  locks: UserLocksModel[] = [];
  assignedLocks: LockGlobalAdmin[] = [];
  lockUsersAccess: LockUsersDetails[] = [];
  user: UserGetResponseRecord;
  users: UserGetResponseRecord[] = [];
  userLocks: UserLocksModel[] = [];
  manageLocks: UserLocksModel[] = [];
  searchedLocks: UserLocksModel[] = [];
  lockUsers: LockUsers[] = [];
  lockUserGroups: LockUserGroup[] = [];
  lockUserGroupMems: LockUserGroupMems[] = [];
  auditDetails: AuditResponseRecord[] = [];

  usersObv = new BehaviorSubject<UserGetResponseRecord[]>([]);
  locksObv = new BehaviorSubject<UserLocksModel[]>([]);
  lockUsersObv = new BehaviorSubject<LockUsers[]>([]);
  lockUserGroupsObv = new BehaviorSubject<LockUserGroup[]>([]);

  LOCALSTORAGE_AUTH_TOKEN = 'authenticationToken';

  constructor(private http: HttpClient, private spinner: NgxSpinnerService,
    private toastr: ToastrService, private router: Router) {
    // this.getUsers();
  }

  downloadAudit(lockRec) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.post(`${downloadAudit}`, lockRec, httpOptions);
  }

  getAuditDetails(lockRec) {
    this.spinner.show();
    this.http.post<AuditResponseRecord[]>(`${auditURL}`, lockRec)
      .subscribe((auditRes: any) => {
        this.auditDetails = [];
        this.auditDetails = auditRes ? auditRes.auditRecords : [];
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        this.toastr.error('Failed to retrieve Audit details', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
      });
  }

  sendMessage(mesReq: SendMessage) {
    this.spinner.show();
    return this.http.post<any>(`${sendMessageURL}`, mesReq);
  }

  addLockUserToGroup(lockUserGroupRec: AddUserToGroupRequest) {
    this.spinner.show();
    return this.http.post<any>(`${addToGroupURL}`, lockUserGroupRec);
  }

  deleteLockUserFromGroup(lockUserGroupRec: LockUserGroup) {
    this.spinner.show();
    return this.http.post<any>(`${deleteFromGroupURL}`, lockUserGroupRec);
  }

  generateCode(lockUser: GenerateCode) {
    this.spinner.show();
    return this.http.post<any>(`${generateCodeURL}`, lockUser);
  }

  generateCodes(lockUsers: GenerateCode[]) {
    this.spinner.show();
    return this.http.post<any>(`${generateCodesURL}`, lockUsers);
  }

  removeCode(lockUser: GenerateCode) {
    this.spinner.show();
    return this.http.post<any>(`${removeCodeURL}`, lockUser);
  }

  removeCodes(lockUsers: GenerateCode[]) {
    this.spinner.show();
    return this.http.post<any>(`${removeCodesURL}`, lockUsers);
  }

  getLockUsers(lockUserRec: UserLocksModel) {
    this.spinner.show();
    this.http.post<LockUsers>(`${lockUsersURL}`, lockUserRec)
      .subscribe((lockUsersRes: any) => {
        this.lockUsers = lockUsersRes ? lockUsersRes.lockUserRecords : [];
        this.storeLockUsers(this.lockUsers);
        this.spinner.hide();
      });
  }

  getLockUsersV2(lockUserRec: UserLocksModel) {
    //this.spinner.show();
    return this.http.post<LockUsers>(`${lockUsersURL}`, lockUserRec);
  }

  storeLockUsers(lockUsers: LockUsers[]) {
    this.lockUsersObv.next(lockUsers);
  }

  getUpdatedLockUsers(): Observable<LockUsers[]> {
    return this.lockUsersObv.asObservable();
  }

  getLockUserAccess(lockUserRec: UserLocksModel) {
    this.spinner.show();
    this.http.post<LockUserAccess>(`${getLockUserAccessURL}`, lockUserRec)
      .subscribe((lockUsersRes: LockUserAccess) => {
        this.lockUsersAccess = lockUsersRes.accessRecords;
        this.spinner.hide();
      });
  }

  getLockUserAccesses(lockUserRec: UserLocksModel) {
    this.spinner.show();
    return this.http.post<LockUserAccess>(`${getLockUserAccessURL}`, lockUserRec);
  }

  getActiveLockUsersAndLocks(getActiveLockUsersAndLocks: GetActiveLockUsersAndLocks = new GetActiveLockUsersAndLocks()) {
    this.spinner.show();
    return this.http.post<ActiveLockUsersAndLocks>(`${getActiveLockUsersAndLocksURL}`, getActiveLockUsersAndLocks);
  }

  getUserLocks(user: UserGetResponseRecord) {
    this.spinner.show();
    this.http.post<UserLocksModel[]>(`${userLocksURL}`, user)
      .subscribe(userLocksRes => {
        this.userLocks = userLocksRes;
        this.spinner.hide();
      });
  }

  getManageLocks() {
    this.spinner.show();
    this.http.post<UserLocksModel[]>(`${userLocksURL}`, {})
      .subscribe(userLocksRes => {
        this.manageLocks = userLocksRes;
        this.spinner.hide();
      });
  }

  getLocks() {
    this.spinner.show();
    this.http.post<UserLocksModel[]>(`${userLocksURL}`, {})
      .subscribe(userLocksRes => {
        this.locks = userLocksRes;
        this.storeLocks(this.locks);
        this.spinner.hide();
      });
  }

  getAssignedLocks(lockGetRequest: LockGetRequest = null) {
    this.spinner.show();
    let request = {};
    if (lockGetRequest) { request = lockGetRequest; }
    this.http.post<LockGlobalAdmin[]>(`${assignedUserLocksURL}`, request)
      .subscribe(userLocksRes => {
        this.assignedLocks = userLocksRes;
        this.spinner.hide();
      });
  }

  getAssignedLocksAsync(lockGetRequest: LockGetRequest = null) {
    this.spinner.show();
    let request = {};
    if (lockGetRequest) { request = lockGetRequest; }
    return this.http.post<LockGlobalAdmin[]>(`${assignedUserLocksURL}`, request);
  }

  getSearchedLocks(lockReq: UserLocksModel) {
    this.spinner.show();
    this.http.post<any>(`${userLocksURL}`, lockReq)
      .subscribe(userLocksRes => {
        this.searchedLocks = userLocksRes;
        this.spinner.hide();
      });
  }

  getActivationCode(unitId) {
    this.spinner.show();
    return this.http.post<any>(`${getActivationCodeURL}`, { 'serialNumber': unitId });
  }

  getUsers() {
    this.spinner.show();
    this.http.post<UserGetResponseRecord[]>(`${usersURL}`, {})
      .subscribe(usersRes => {
        this.users = usersRes;
        this.storeUsers(this.users);
        this.spinner.hide();
      });
  }

  storeUsers(users: UserGetResponseRecord[]) {
    this.usersObv.next(users);
  }

  getUpdatedUsers(): Observable<UserGetResponseRecord[]> {
    return this.usersObv.asObservable();
  }

  storeLocks(locks: UserLocksModel[]) {
    this.locksObv.next(locks);
  }

  fetchUpdatedLocks(): Observable<UserLocksModel[]> {
    return this.locksObv.asObservable();
  }

  getUser(user: UserGetRequest) {
    this.spinner.show();
    return this.http.post<UserGetResponseRecord>(`${getUserURL}`, user);
  }

  updateUser(payload: UserUpdateRequest) {
    this.spinner.show();
    return this.http.post<any>(`${updateUserURL}`, payload);
  }

  createLockUserGroup(createGroupForm) {
    this.spinner.show();
    return this.http.post<any>(`${createLockUserGroupURL}`, createGroupForm);
  }

  readLockUserGroups() {
    this.spinner.show();
    return this.http.post<any>(`${readLockUserGroupsURL}`, {})
      .subscribe((lockUserGroupsRes: any) => {
        this.lockUserGroups = lockUserGroupsRes ? lockUserGroupsRes.lockUserGroupRecords : [];
        this.storeLockUserGroups(this.lockUserGroups);
        this.spinner.hide();
      });
  }

  getLockUserGroupMems(groupDetails) {
    this.spinner.show();
    this.http.post<LockUserGroupMems[]>(`${getLockUserGroupMemsURL}`, groupDetails)
      .subscribe((lockUserGroupMemsRes: any) => {
        this.lockUserGroupMems = lockUserGroupMemsRes;
        this.spinner.hide();
      });
  }

  storeLockUserGroups(lockUserGroups: LockUserGroup[]) {
    this.lockUserGroupsObv.next(lockUserGroups);
  }

  getUpdatedLockUserGroups(): Observable<LockUserGroup[]> {
    return this.lockUserGroupsObv.asObservable();
  }

  deleteLockUserGroup(deleteGroupForm) {
    this.spinner.show();
    return this.http.post<any>(`${deleteLockUserGroupURL}`, deleteGroupForm);
  }

  addNewUser(newUserForm) {
    this.spinner.show();
    return this.http.post<any>(`${addNewUserURL}`, newUserForm);
  }

  addNewLockUser(newUserForm: LockUsers) {
    this.spinner.show();
    return this.http.post<any>(`${addNewLockUserURL}`, newUserForm);
  }

  updateLockUser(lockUser: LockUsers) {
    this.spinner.show();
    return this.http.post<any>(`${updateLockUserURL}`, lockUser);
  }

  updateLockUsers(lockUsers: LockUsers[]) {
    this.spinner.show();
    return this.http.post<any>(`${updateLockUsersURL}`, lockUsers);
  }

  deleteLockUser(lockUserForm) {
    this.spinner.show();
    return this.http.post<any>(`${deleteLockUserURL}`, lockUserForm);
  }

  deleteUser(userForm) {
    this.spinner.show();
    return this.http.post<any>(`${deleteUserURL}`, userForm);
  }

  createOwnerUserAndActivateLock(ownerUserForm) {
    this.spinner.show();
    return this.http.post<any>(`${createOwnerUserAndActivateLock}`, ownerUserForm);
  }

  activateLock(activateLockForm) {
    this.spinner.show();
    return this.http.post<any>(`${activateLockURL}`, activateLockForm);
  }

  activateMultiLocks(activateMultiLocks) {
    this.spinner.show();
    return this.http.post<any>(`${activateLocksURL}`, activateMultiLocks);
  }

  deActivateLock(request) {
    this.spinner.show();
    return this.http.put<any>(`${deActivateLockURL}`, request);
  }

  updateLock(cloneLockReq: LockUpdateRequest) {
    this.spinner.show();
    return this.http.post<any>(`${updateLockURL}`, cloneLockReq);
  }

  logout() {
    localStorage.removeItem(this.LOCALSTORAGE_AUTH_TOKEN);
    this.router.navigate(['login']);
    this.spinner.hide();

    this.clearUserNGroupsData();
  }

  getAccessToken() {
    return localStorage.getItem(this.LOCALSTORAGE_AUTH_TOKEN);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  loadUsersGroups() {
    let userRole = '';
    const token = this.getAccessToken();
    const decodedToken = this.getDecodedAccessToken(token);

    if (decodedToken) {
      userRole = decodedToken.Role;
    }

    if (userRole && userRole != 'GlobalAdmin') {
      if (decodedToken && this.lockUserGroups && this.lockUserGroups.length == 0) {
        this.readLockUserGroups();
      }
      if (decodedToken && this.lockUsers && this.lockUsers.length == 0) {
        this.getLockUsers(new UserLocksModel());
      }
    }

    if (decodedToken && this.users && this.users.length == 0) {
      this.getUsers();
    }
  }

  generateCodeForAuditCollector(payload: UserUpdateRequest) {
    this.spinner.show();
    return this.http.post<any>(`${updateUserURL}`, payload);
  }

  clearUserNGroupsData() {
    this.lockUserGroups.length = 0;
    this.lockUsers.length = 0;
    this.users.length = 0;

    this.locks.length = 0;
    this.lockUsersAccess.length = 0;
    this.userLocks.length = 0;
    this.manageLocks.length = 0;
    this.searchedLocks.length = 0;
    this.lockUserGroupMems.length = 0;
    this.auditDetails.length = 0;
  }
}
