import { Component, HostListener, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DataService } from '../shared/dataService';
import { Repository } from '../models/repository';
import { UserGetResponseRecord } from '../models/userGetResponseRecord.model';
import { UserLocksModel } from '../models/UserLocksModel.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { LockUsersDetails } from '../models/LockUserAccess.model';
import { LockUsers } from '../models/LockUsers.model';
import { LockUserGroup } from '../models/LockUserGroup.model';
import { Table } from 'primeng/table';
import { UserUpdateRequest } from '../models/UserUpdateRequest.model';
import * as AppConstants from '../common/app.constants';
import { AppRoles } from '../common/app.roles';
import { forkJoin } from 'rxjs';
import { AppNotification } from '../common/app.notification';
import { LockGetRequest } from '../models/LockGetRequest.model';
import { UserGetRequest } from '../models/UserGetRequest.model';
import * as AppHelper from '../common/app.helper';
import { SendMessage } from '../models/SendMessage.model';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class User {
  viewUserLocks = false;
  viewLockUsers = false;

  showAddUserDialog = false;
  showAddLockUserDialog = false;
  showDeleteUserDialog = false;
  showDeleteLockUserDialog = false;
  showConvertLockUserDialog = false;
  showConfirmationSection = false;
  showEditContactDetailsDialog = false;
  showResendUserCodeDialog = false;
  id = '';
  usersCols: any[];
  lockUsersCols: any[];
  lockUsersCols_lockUser: any[];
  lockUsersCols_user: any[];
  lockUsersCols_auditCollector: any[];
  userLocksCols: any[];
  OwnerUserLocksCols: any[];
  roleForDropdown: any[];
  rolesForDelete: any[];
  selectedUser: UserGetResponseRecord;
  selectedLockUser: LockUsers;

  addUserForm: FormGroup;
  deleteUserForm: FormGroup;
  addLockUserForm: FormGroup;
  convertLockUserForm: FormGroup;
  deleteLockUserForm: FormGroup;
  lockUserGroupForm: FormGroup;
  lockUserDeleteGroupForm: FormGroup;
  userRolesForm: FormGroup;
  editContactDetailsForm: FormGroup;
  searchUsersForm: FormGroup;
  resendUserCodeForm: FormGroup;

  tblDheight = AppConstants.Generic.tableHeight;
  userRole: string;
  selectedUserRole = this.roles.lockUser;
  roleOfUserBeingDeleted = '';
  role_user_auditCollector = this.roles.user + "," + this.roles.auditCollector;
  constSingle = AppConstants.Generic.single;
  constMultiple = AppConstants.Generic.multiple;
  labelUsername = this.appLabels.userName;
  isSingleUserCreation = true;

  groupsArray: Array<LockUserGroup> = this.repo.lockUserGroups;
  usersArray: Array<UserGetResponseRecord> = this.repo.users;
  lockUsersArray: Array<LockUsers> = this.repo.lockUsers;
  lockUsersDropDown: Array<any> = [];

  /** Gets or sets the number of rows per page to be displayed. */
  rowsPerPage = AppConstants.Generic.rowsPerPage;
  /** Gets or sets the title for the Units page.*/
  pageTitle = AppConstants.PageTitle.users;

  /** Gets or sets the label for add user element.*/
  labelAddNonLockUser = this.appLabels.addUser;

  /** Gets or sets the label for delete user element.*/
  labelDeleteNonLockUser = this.appLabels.deleteUser;

  /** Gets or sets the options menu width.*/
  optionsMenuWidth = '360px';

  groupsDropDown: Array<any> = [];

  usersDropDown: Array<any> = [];

  nonArbitraryLockUsers: Array<any> = [];

  arbitaryCodeDropdown = [
    { label: this.appLabels.yes, value: 1 },
    { label: this.appLabels.no, value: 0 }
  ];

  ownerRolesDropdown = [
    { label: this.roles.lockUserLabel, value: this.roles.lockUser },
    { label: this.roles.userLabel, value: this.roles.user },
    { label: this.roles.auditCollectorLabel, value: this.roles.auditCollector }
  ];

  userRolesDropdown = [
    { label: this.roles.lockUserLabel, value: this.roles.lockUser },
    { label: this.roles.auditCollectorLabel, value: this.roles.auditCollector }
  ];

  @ViewChild('usersTable', { static: false })
  private usersTable: Table;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.tblDheight = $(window).height() - 260 + 'px';
  }

  constructor(private repo: Repository, private router: Router, private formBuilder: FormBuilder, private roles: AppRoles,
    private spinner: NgxSpinnerService, private dataService: DataService,
    private appLabels: AppConstants.AppLabels, private appNotification: AppNotification,
    private apptimeZone: AppHelper.AppTimeZone) {
    this.tblDheight = $(window).height() - 260 + 'px';

    this.viewUserLocks = false;
    this.viewLockUsers = false;

    const token = dataService.getAccessToken();
    const decodedToken = dataService.getDecodedAccessToken(token);
    if (decodedToken) {
      this.userRole = decodedToken.Role;

      if (this.userRole && this.userRole.toLowerCase() == this.roles.ownerUser.toLowerCase()) {
        this.labelAddNonLockUser = this.appLabels.addUserByOwnerUser;
        this.labelDeleteNonLockUser = this.appLabels.deleteUserByOwnerUser;
        this.optionsMenuWidth = '375px';
        this.roleForDropdown = [
          { label: this.roles.userLabel, value: this.roles.user },
          { label: this.roles.auditCollectorLabel, value: this.roles.auditCollector }
        ];
        this.rolesForDelete = [
          { label: this.roles.userLabel, value: this.roles.user },
          { label: this.roles.auditCollectorLabel, value: this.roles.auditCollector },
          { label: this.roles.userLabel + ' + ' + this.roles.auditCollectorLabel, value: this.role_user_auditCollector }
        ];
      } else if (this.userRole && this.userRole.toLowerCase() == this.roles.globalAdmin.toLowerCase()) {
        this.optionsMenuWidth = '385px';
        this.labelAddNonLockUser = this.appLabels.addUserByGlobalAdmin;
        this.labelDeleteNonLockUser = this.appLabels.deleteUserByGlobalAdmin;
        this.roleForDropdown = [
          { label: this.roles.ownerUserLabel, value: this.roles.ownerUser },
          { label: this.roles.auditCollectorLabel, value: this.roles.auditCollector }
        ];
      } else if (this.userRole && this.userRole.toLowerCase() == this.roles.user.toLowerCase()) {
        this.labelAddNonLockUser = this.appLabels.addUserByUser;
        this.labelDeleteNonLockUser = this.appLabels.deleteUserByUser;
      }
    }

    this.getManageGridDetails();

    this.usersCols = [
      { field: 'userName', header: appLabels.userName },
      { field: 'firstName', header: appLabels.firstName },
      { field: 'lastName', header: appLabels.lastName },
      { field: 'roles', header: appLabels.role },
      { field: 'email', header: appLabels.email },
      { field: 'phone', header: appLabels.phone },
      { field: 'company', header: appLabels.company },
      { field: 'group', header: appLabels.group }
    ];

    this.lockUsersCols = this.lockUsersCols_lockUser = [
      { field: 'name', header: appLabels.userName },
      { field: 'selectedUserRole', header: appLabels.role },
      { field: 'email', header: appLabels.email },
      { field: 'phone', header: appLabels.phone },
      { field: 'company', header: appLabels.company },
      { field: 'groups', header: appLabels.group }
    ];

    this.lockUsersCols_user = [
      { field: 'userName', header: appLabels.userName },
      { field: 'firstName', header: appLabels.firstName },
      { field: 'lastName', header: appLabels.lastName },
      { field: 'roles', header: appLabels.role },
      { field: 'email', header: appLabels.email },
      { field: 'phone', header: appLabels.phone },
      { field: 'company', header: appLabels.company },
      { field: 'groups', header: appLabels.group }
    ];

    this.lockUsersCols_auditCollector = [
      { field: 'userName', header: appLabels.userName },
      { field: 'firstName', header: appLabels.firstName },
      { field: 'lastName', header: appLabels.lastName },
      { field: 'roles', header: appLabels.role },
      { field: 'email', header: appLabels.email },
      { field: 'phone', header: appLabels.phone },
      { field: 'company', header: appLabels.company },
      { field: 'groups', header: appLabels.group }
    ];

    this.userLocksCols = [
      { field: 'unitIdNo', header: appLabels.lockId },
      { field: 'location', header: appLabels.location },
      { field: 'unitType', header: appLabels.lockType },
      { field: 'codeType', header: appLabels.codeType },
      { field: 'installedDate', header: appLabels.dateInstalled }
    ];

    this.OwnerUserLocksCols = [
      { field: 'lockSerialNumber', header: appLabels.lockId },
      { field: 'lockLocation', header: appLabels.location },
      { field: 'added', header: appLabels.dateInstalled }
    ];

    this.repo.getUpdatedLockUserGroups().subscribe((lockUserGroups: LockUserGroup[]) => {
      if (lockUserGroups && lockUserGroups.length > 0) {
        this.groupsArray = lockUserGroups;
        this.buildGroupsDropDown();
      }
    });

    this.repo.getUpdatedUsers().subscribe((users: UserGetResponseRecord[]) => {
      if (users && users.length > 0) {
        this.usersArray = users;
        this.buildUsersDropDown();
      }
    });

    this.addUserForm = this.formBuilder.group({
      userName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      roleForUser: ['', Validators.required],
      company: ['']
    });

    this.editContactDetailsForm = this.formBuilder.group({
      name: ['', Validators.required],
      userName: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      newName: [''],
      company: ['']
    });

    this.searchUsersForm = this.formBuilder.group({
      searchText: ['']
    });

    this.addLockUserForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: [''],
      email: [''],
      generateArbitraryCode: [0, Validators.required],
      company: [''],
      countOfUsers: [0],
      addUsersStartingRange: [0],
      isSingleOrMultiUsers: [this.constSingle.toLowerCase()]
    });

    this.convertLockUserForm = this.formBuilder.group({
      nonArbitraryLockUser: ['', Validators.required]
    });

    this.lockUserGroupForm = this.formBuilder.group({
      groupName: ['', Validators.required]
    });

    this.lockUserDeleteGroupForm = this.formBuilder.group({
      groupName: ['', Validators.required]
    });

    this.deleteUserForm = this.formBuilder.group({
      userName: ['', Validators.required],
      roleToDelete: [this.roles.user, Validators.required]
    });

    this.deleteLockUserForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    this.userRolesForm = this.formBuilder.group({
      userRole: [this.roles.lockUser]
    });

    this.resendUserCodeForm = this.formBuilder.group({
      lockSerialNumber: [''],
      lockUserId: [''],
      lockUser: ['', Validators.required],
      messageMedium: ['', Validators.required]
    });
  }

  switchUsers() {
    if (this.userRolesForm.valid) {
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
        this.selectedUserRole = this.userRolesForm.controls['userRole'].value;
      }, 1000);
    } else {
      this.appNotification.error('Please select a value');
    }
  }

  buildGroupsDropDown() {
    if (this.groupsArray) {
      this.groupsDropDown = [];
      this.groupsArray.forEach((lockUserGroup: LockUserGroup) => {
        this.groupsDropDown.push(
          { label: lockUserGroup.groupName, value: lockUserGroup.groupName }
        );
      });
    }
  }

  buildUsersDropDown(userRoleToBuild: string = null) {
    if (this.usersArray) {
      this.usersDropDown = [];
      this.usersArray.forEach((lockUserGroup: UserGetResponseRecord) => {
        if (userRoleToBuild && userRoleToBuild == this.roles.auditCollector && lockUserGroup.roles && lockUserGroup.roles == this.roles.auditCollector) {
          this.usersDropDown.push(
            { label: lockUserGroup.userName, value: lockUserGroup.userName }
          );
        }
        else if (!userRoleToBuild || userRoleToBuild == this.roles.user) {
          if (this.userRole == this.roles.globalAdmin && lockUserGroup.roles && lockUserGroup.roles.toLowerCase().includes(this.roles.ownerUser.toLowerCase())) {
            this.usersDropDown.push(
              { label: lockUserGroup.userName, value: lockUserGroup.userName }
            );
          } else if (this.userRole == this.roles.ownerUser && lockUserGroup.roles && lockUserGroup.roles == this.roles.user) {
            this.usersDropDown.push(
              { label: lockUserGroup.userName, value: lockUserGroup.userName }
            );
          } else if (this.userRole == this.roles.user && lockUserGroup.roles && lockUserGroup.roles == this.roles.auditCollector) {
            this.usersDropDown.push(
              { label: lockUserGroup.userName, value: lockUserGroup.userName }
            );
          }
        }
        else if (userRoleToBuild && userRoleToBuild == this.role_user_auditCollector && lockUserGroup.roles && lockUserGroup.roles == this.role_user_auditCollector) {
          this.usersDropDown.push(
            { label: lockUserGroup.userName, value: lockUserGroup.userName }
          );
        }
      });
    }
  }

  buildNonArbitraryLockUsersDropDown() {
    if (this.lockUsers) {
      this.nonArbitraryLockUsers = [];
      this.lockUsers.filter(lockUser => lockUser.arbitraryKeypadCode == 0 || lockUser.arbitraryKeypadCode == null).forEach((lockUser: LockUsers) => {
        this.nonArbitraryLockUsers.push(
          { label: lockUser.name, value: lockUser }
        );
      });
    }
  }

  searchUsers() {
    this.nonAdminUsers;
  }

  clearSearchUsersForm() {
    this.nonAdminUsers;
  }

  confirmAndConvertLockUser() {
    if (this.convertLockUserForm.valid) {
      this.showConfirmationSection = true;
    } else {
      this.appNotification.error('Select atleast one user to convert.');
    }
  }

  closeConfirmationSection() {
    this.showConfirmationSection = false;
  }

  convertLockUser() {
    const convertLockUserForm = this.convertLockUserForm;
    if (convertLockUserForm.valid) {
      const lockUsers: LockUsers[] = convertLockUserForm.controls['nonArbitraryLockUser'].value ? convertLockUserForm.controls['nonArbitraryLockUser'].value : [];
      const observables = [];

      lockUsers.forEach((lockUser) => {
        if (lockUser.id && lockUser.id > 0
          && (!lockUser.arbitraryKeypadCode || lockUser.arbitraryKeypadCode < 1)) {
          const lockUserUpdateReq = new LockUsers();
          lockUserUpdateReq.id = lockUser.id;
          lockUserUpdateReq.name = lockUser.name;
          lockUserUpdateReq.phone = lockUser.phone;
          lockUserUpdateReq.email = lockUser.email;
          lockUserUpdateReq.generateArbitraryCode = 0;
          lockUserUpdateReq.updateArbitraryCode = 1;
          observables.push(this.repo.updateLockUser(lockUserUpdateReq));
        }
      });

      if (observables.length) {
        forkJoin(observables).subscribe((results: LockUsersDetails[]) => {
          results.forEach(result => {
            if (result && result.keypadCode) {
            }
          });
          this.closeConvertLockUserDialog();
          this.closeConfirmationSection();
          this.spinner.hide();
          this.appNotification.success('Successfully converted user(s).');
          this.repo.getLockUsers(new UserLocksModel());
        }, (err) => { // TODO: handle error for individual requests
          this.spinner.hide();
          this.appNotification.error('Error! Something went wrong while converting user(s).');
          console.error(err);
        });
      }
    } else {
      this.appNotification.error('Select atleast one user to convert.');
    }
  }

  getManageGridDetails() {
    if (this.userRole && this.userRole == this.roles.globalAdmin) {
      this.repo.getUsers();
    } else if (this.userRole && (this.userRole == this.roles.ownerUser || this.userRole == this.roles.user)) {
      this.repo.getLockUsers(new UserLocksModel());
    } else {
      this.repo.getLockUsers(new UserLocksModel());
    }
  }

  get currentTimeZoneOffset(): string {
    return this.apptimeZone.currentTimeZoneOffset;
  }

  get currentTimeZoneName(): string {
    return this.apptimeZone.currentTimeZoneName;
  }

  get users(): UserGetResponseRecord[] {
    return this.repo.users;
  }

  get lockUsers(): LockUsers[] {
    return this.repo.lockUsers;
  }


  /** Gets the list of Owner users and Audit Collectors. */
  get ownerUsersAuditCollectors(): any[] {
    let usersData = this.users.filter(user => user.roles
      && user.roles.toLowerCase().split(",").filter(role => role == this.roles.ownerUser.toLowerCase()
        || role == this.roles.auditCollector.toLowerCase()).length > 0
    );

    usersData = usersData.map(this.setRolesToDisplay);
    return usersData;
  }

  /** Gets the list of non-admin users. */
  get nonAdminUsers(): any[] {
    const userSearchText = this.searchUsersForm.controls['searchText'];
    const userSearchTextValue = userSearchText ? userSearchText.value.trim() : '';

    const lockUsers = this.lockUsers.filter(user => user.name.includes(userSearchTextValue)
      || userSearchTextValue === '');

    const users = this.users.filter(user => user.userName.includes(userSearchTextValue)
      || user.firstName.includes(userSearchTextValue)
      || user.lastName.includes(userSearchTextValue) || userSearchTextValue === '');

    let userData = [];

    if (this.selectedUserRole === this.roles.lockUser) {
      userData = lockUsers;
      this.lockUsersCols = this.lockUsersCols_lockUser;
    }
    else if (this.selectedUserRole === this.roles.user) {
      userData = users.filter(user => user.roles === this.roles.user
        || user.roles.toLowerCase().split(",").filter(role => role == this.roles.user.toLowerCase()).length > 0);
      this.lockUsersCols = this.lockUsersCols_user;
    }
    else if (this.selectedUserRole === this.roles.auditCollector) {
      userData = users.filter(user => user.roles === this.roles.auditCollector
        || user.roles.toLowerCase().split(",").filter(role => role == this.roles.auditCollector.toLowerCase()).length > 0);
      this.lockUsersCols = this.lockUsersCols_auditCollector;
    }

    userData = userData.map(this.setRolesToDisplay)
    return userData;

    // return this.selectedUserRole === this.roles.lockUser
    //  ? lockUsers
    //  : this.selectedUserRole == this.roles.user
    //    ? users.filter(user => user.roles == this.roles.user)
    //    : this.selectedUserRole == this.roles.auditCollector
    //      ? users.filter(user => user.roles == this.roles.auditCollector)
    //      : [];
  }

  get userLocks(): LockUsersDetails[] {
    return this.repo.lockUsersAccess;
  }

  get distinctUserLocks(): LockUsersDetails[] {
    return this.userLocks.sort().filter(function (item, pos, ary) {
      return !pos || item.lockSerialNumber != ary[pos - 1].lockSerialNumber;
    });
  }

  get manageOwnerUserLocks(): UserLocksModel[] {
    return this.repo.manageLocks;
  }

  setRolesToDisplay(role: UserGetResponseRecord) {
    let rolesMasterData = new AppRoles();
    if (role.roles) {
      role.rolesToDisplay = role.roles
        .replace(",", ", ")
        .replace(rolesMasterData.auditCollector, rolesMasterData.auditCollectorLabel)
        .replace(rolesMasterData.globalAdmin, rolesMasterData.globalAdminLabel)
        .replace(rolesMasterData.lockUser, rolesMasterData.lockUserLabel)
        .replace(rolesMasterData.ownerUser, rolesMasterData.ownerUserLabel)
        .replace(rolesMasterData.user, rolesMasterData.userLabel)
    }

    return role;
  }

  viewSelUserLocks(user) {
    this.viewUserLocks = true;
    this.repo.getUserLocks(user);
    this.selectedUser = user;
  }

  viewSelLocksUserAccess(user: LockUsers) {
    this.viewLockUsers = true;
    const reqObj: UserLocksModel = new UserLocksModel();
    reqObj.lockUserName = user.name;
    reqObj.lockUserId = user.id;
    this.repo.getLockUserAccess(reqObj);
    this.selectedLockUser = user;
  }

  backToUsers() {
    this.viewUserLocks = false;
  }

  backToLockUsers() {
    this.viewLockUsers = false;
  }

  editContactDetails() {
    const editForm = this.editContactDetailsForm;
    let isFormValid = (editForm.controls['name'].valid || editForm.controls['userName'].valid)
      && editForm.controls['email'].valid && editForm.controls['phone'].valid && editForm.controls['phone'].value != 'phone';

    if (this.selectedUserRole == this.roles.lockUser) {
      isFormValid = (editForm.controls['name'].valid || editForm.controls['userName'].valid);
      editForm.controls['phone'].setValue(editForm.controls['phone'].value && editForm.controls['phone'].value != 'phone' ? editForm.controls['phone'].value : '');
    }

    const newName = editForm.controls['newName'].value;
    const newNameValue = newName ? newName.trim() : newName;
    editForm.controls['newName'].setValue(newNameValue == '' ? null : newNameValue);

    if (isFormValid) {
      editForm.controls['phone'].setValue(String(editForm.controls['phone'].value));
      if (this.selectedUserRole == this.roles.lockUser) {
        this.repo.updateLockUser(editForm.value).subscribe((response: any) => {
          this.spinner.hide();
          if (response.statusCode == AppConstants.StatusCode.Ok) {
            this.closeEditContactDetailsDialog();
            this.appNotification.success('Updated the user information.');
            this.repo.getLockUsers(new UserLocksModel());
          } else {
            console.error('StatusCode : ', response.statusCode, '. Message : ', response.message);
            this.appNotification.error(`Failed to update. ${response.message}`);
          }
        }, (err) => {
          console.error(err);
          this.spinner.hide();
          this.appNotification.error('Failed to update. Something went wrong!');
        });
      } else if (this.selectedUserRole == this.roles.auditCollector) {
        this.repo.updateUser(editForm.value).subscribe((response: any) => {
          this.spinner.hide();
          if (response.statusCode == AppConstants.StatusCode.Ok) {
            this.closeEditContactDetailsDialog();
            this.appNotification.success('Updated the user information.');
            this.repo.getUsers();
          } else {
            console.error('StatusCode : ', response.statusCode, '. Message : ', response.message);
            this.appNotification.error(`Failed to update. ${response.message}`);
          }
        }, (err) => {
          console.error(err);
          this.spinner.hide();
          this.appNotification.error('Failed to update. Something went wrong!');
        });
      }
    } else {
      if (!editForm.controls['email'].valid && !editForm.controls['phone'].valid) {
        this.appNotification.error('Please provide all the inputs.', 'Input required');
      } else if (!editForm.controls['email'].valid) {
        this.appNotification.error('Provide a valid email address.', 'Invalid input');
      } else if (!editForm.controls['phone'].valid || editForm.controls['phone'].value == 'phone') {
        this.appNotification.error('Provide a valid phone number.', 'Invalid input');
      }
    }
  }

  openEditContactDetailsDialog(user: any) {
    this.editContactDetailsForm.reset();
    if (user) {
      if (user.name) { this.editContactDetailsForm.controls['name'].setValue(user.name); } else if (user.userName) { this.editContactDetailsForm.controls['userName'].setValue(user.userName); }

      if (user.email) { this.editContactDetailsForm.controls['email'].setValue(user.email); }
      if (user.phone) { this.editContactDetailsForm.controls['phone'].setValue(user.phone); }
      if (user.company) { this.editContactDetailsForm.controls['company'].setValue(user.company); }
    }
    this.showEditContactDetailsDialog = true;
  }

  showAddUser() {
    this.addUserForm.reset();
    this.showAddUserDialog = true;
  }

  showAddLockUser() {
    this.resetAddLockUser();
    this.showAddLockUserDialog = true;
  }

  resetAddLockUser() {
    this.addLockUserForm.reset();
    this.addLockUserForm.controls['isSingleOrMultiUsers'].setValue(this.constSingle.toLowerCase());
    this.isSingleUserCreation = true;
    this.labelUsername = this.appLabels.userName;
  }

  showConvertLockUser() {
    this.convertLockUserForm.reset();
    this.buildNonArbitraryLockUsersDropDown();
    this.showConvertLockUserDialog = true;
  }

  showDeleteLockUser() {
    this.deleteLockUserForm.reset();
    this.showDeleteLockUserDialog = true;
  }

  showDeleteUser() {
    this.deleteUserForm.reset();
    this.deleteUserForm.controls['roleToDelete'].setValue(this.roles.user);
    this.showDeleteUserDialog = true;
  }

  closeEditContactDetailsDialog() {
    this.showEditContactDetailsDialog = false;
  }

  closeAddUserDialog() {
    this.showAddUserDialog = false;
    this.resetAddLockUser();
  }

  closeConvertLockUserDialog() {
    this.showConvertLockUserDialog = false;
  }

  closeAddLockUserDialog() {
    this.showAddLockUserDialog = false;
  }

  closeDeleteLockUserDialog() {
    this.showDeleteLockUserDialog = false;
  }

  closeDeleteUserDialog() {
    this.showDeleteUserDialog = false;
  }

  addNewUser() {
    const addUserRolesInContext = this.addUserForm.controls['roleForUser'].value;
    const form = this.addUserForm;
    //if (this.userRole == this.roles.globalAdmin) {
    //  form.controls['roleForUser'].setValue(this.roles.ownerUser);
    //}
    if (this.userRole == this.roles.user) {
      form.controls['roleForUser'].setValue([this.roles.auditCollector]);
    }
    if (form.valid) {
      const rolesSelected: [] = form.controls['roleForUser'].value ? form.controls['roleForUser'].value : [];
      let roles = rolesSelected.length > 0 ? rolesSelected.join("|") : rolesSelected;
      form.controls['roleForUser'].setValue(roles);

      let input = form.value
      form.controls['roleForUser'].setValue(addUserRolesInContext);

      this.repo.addNewUser(input).subscribe(addUserRes => {
        this.spinner.hide();
        if (addUserRes.statusCode != AppConstants.StatusCode.Ok) {
          this.appNotification.error(addUserRes.message);
        } else if (!addUserRes.message) {
          this.appNotification.error('Failed to add new user (or) User already exists.');
        } else {
          this.repo.getUsers();
          this.appNotification.success(addUserRes.message);
          this.closeAddUserDialog();
        }
      }, (err) => {
        this.spinner.hide();
        this.appNotification.error('Failed to add new user');
        this.closeAddUserDialog();
      });
    } else {
      this.appNotification.error('Please Provide all the inputs.', 'Input required');
    }
  }

  addNewLockUser() {
    const form = this.addLockUserForm;

    if (!form.controls['countOfUsers'].value) {
      form.controls['countOfUsers'].setValue(1);
    }
    if (!form.controls['addUsersStartingRange'].value) {
      form.controls['addUsersStartingRange'].setValue(0);
    }

    if (form.valid) {
      this.repo.addNewLockUser(form.value).subscribe(addLockUserRes => {
        this.repo.getLockUsers(new UserLocksModel());
        this.spinner.hide();
        let response = addLockUserRes.message;
        if (String(response).toLowerCase().includes('fail')) {
          var messageTitle = 'Failed adding users';
          if (String(response).toLowerCase().includes('success'))
            messageTitle = 'Failed adding some users';
          this.appNotification.error(response, messageTitle, 20000);
        }
        else
          this.appNotification.success(response);
        this.closeAddLockUserDialog();
      }, (err) => {
        this.spinner.hide();
        this.appNotification.error('Failed to add new user.');
        this.closeAddLockUserDialog();
      });
    } else {
      this.appNotification.error('Please Provide all the inputs.', 'Input required');
    }
  }

  deleteLockUser() {
    const form = this.deleteLockUserForm;
    if (form.valid) {
      this.repo.deleteLockUser(form.value).subscribe(deleteLockUserRes => {
        this.spinner.hide();
        this.repo.getLockUsers(new UserLocksModel());
        this.appNotification.success(`Deleted the ${this.roles.lockUserLabel} successfully`, 'Success');
        this.closeDeleteLockUserDialog();
      }, (err) => {
        this.spinner.hide();
        console.error(err);
        this.appNotification.error(`Failed deleting the ${this.roles.lockUserLabel}`, 'Something went wrong');
      });
    } else {
      this.appNotification.error('Please Provide all the inputs.', 'Input required');
    }
  }

  deleteUser() {
    const form = this.deleteUserForm;

    if (this.userRole == this.roles.globalAdmin) {
      form.controls['roleToDelete'].setValue(this.roles.ownerUser);
      this.roleOfUserBeingDeleted = this.roles.ownerUserLabel;
    }
    if (this.userRole == this.roles.user) {
      form.controls['roleToDelete'].setValue(this.roles.auditCollector);
      this.roleOfUserBeingDeleted = this.roles.auditCollectorLabel;
    }

    if (form.valid) {
      this.roleOfUserBeingDeleted = this.roleOfUserBeingDeleted != null && this.roleOfUserBeingDeleted != undefined && this.roleOfUserBeingDeleted != ''
        ? this.roleOfUserBeingDeleted
        : this.roles.userLabel;
      const userDetails = new UserGetRequest();
      userDetails.userName = form.controls['userName'].value;
      this.repo.getUser(userDetails).subscribe(user => {
        if (user && user.id && user.id > 0) {
          const lockGetRequest = new LockGetRequest();
          lockGetRequest.ownerUserId = user.id;
          this.repo.getAssignedLocksAsync(lockGetRequest).subscribe(locks => {
            if (locks && locks.length > 0) {
              this.spinner.hide();
              this.appNotification.error(`The selected ${this.roleOfUserBeingDeleted}, ${form.controls['userName'].value}, have locks assigned. Please make sure the owner does not have any locks and then delete.`, 'Cannot delete the owner');
            } else {
              this.repo.deleteUser(form.value).subscribe(deleteUserRes => {
                this.spinner.hide();
                this.repo.getUsers();
                if (!deleteUserRes.message) {
                  this.appNotification.error(`Failed deleting ${this.roleOfUserBeingDeleted}`, 'Something went wrong');
                } else {
                  this.appNotification.success(`Deleted the ${this.roleOfUserBeingDeleted} successfully`, 'Success');
                }
                this.closeDeleteUserDialog();
              }, (err) => {
                this.spinner.hide();
                console.error(err);
                this.appNotification.error(`Failed deleting ${this.roleOfUserBeingDeleted}`, 'Something went wrong');
              });
            }
          });
        } else {
          this.spinner.hide();
          this.appNotification.error('User not found.', 'Not Found!');
        }
      });
    } else {
      this.appNotification.error('Please Provide all the inputs', 'Input required');
    }
  }

  viewManageUnits(userLockDetails: LockUsersDetails) {
    const navExtras: NavigationExtras = {
      queryParams: {
        serialNumber: userLockDetails.serialNumber,
        lockUserName: this.selectedLockUser.name,
        lockUserId: this.selectedLockUser.id
      }
    };
    this.router.navigate(['/codes'], {
      state: { selectedLockDetails: navExtras }
    });
  }

  generateNewActivationCode(username: string) {
    const updatePayload = new UserUpdateRequest();

    updatePayload.userName = username;
    updatePayload.generateNewActivationCode = 1;

    this.spinner.show();
    this.repo.generateCodeForAuditCollector(updatePayload).subscribe(generateCodeRes => {
      this.spinner.hide();
      if (!generateCodeRes.message) {
        this.appNotification.error('Failed to Generate Activation Code');
      } else {
        this.appNotification.success('Successfully generated new Activation code');
      }
    }, (err) => {
      this.spinner.hide();
      this.appNotification.error('Failed to Generate Activation Code');
    });
  }

  onUserTypeChange(event) {
    if (event && event.value) {
      this.switchUsers();
    }
  }

  onDeleteUserDropdownChange() {
    this.roleOfUserBeingDeleted = this.deleteUserForm.controls['roleToDelete'].value;
    if (this.roleOfUserBeingDeleted) {
      this.buildUsersDropDown(this.roleOfUserBeingDeleted);
    }
  }

  onChangeSingleOrMultipleUsers(selection) {
    if (selection && selection.toLowerCase() == this.constMultiple.toLowerCase()) {
      this.isSingleUserCreation = false;
      this.labelUsername = this.appLabels.userName + ' PREFIX';
    }
    else {
      this.isSingleUserCreation = true;
      this.labelUsername = this.appLabels.userName;
    }
  }

  openResendUserCodeDialog() {
    this.resendUserCodeForm.reset();
    this.showResendUserCodeDialog = true;
    this.buildLockUsersDropDown();
  }

  closeResendUserCodeDialog() {
    this.showResendUserCodeDialog = false;
  }

  resendUserCode() {
    const form = this.resendUserCodeForm;
    if (form.valid) {
      let lockUsersDetailsWithCode = [];
      const lockUserIds: any[] = form.controls['lockUser'].value ? form.controls['lockUser'].value : [];
      const messageMedium = form.controls['messageMedium'].value;

      lockUserIds.forEach((lockUserId) => {
        const lockUsersDetails = new LockUsersDetails();
        lockUsersDetails.lockUserId = lockUserId;
        lockUsersDetailsWithCode.push(lockUsersDetails);
      });
      this.sendMessage(AppConstants.Generic.messageTypeArbitraryCode, lockUsersDetailsWithCode, messageMedium);
      this.closeResendUserCodeDialog();
    } else {
      this.appNotification.error('Please provide all inputs.', 'Input required');
    }
  }

  sendMessage(messageType: string, lockUsersDetailsWithCode: any[], messageMedium?: string) {
    const genCodeRes = lockUsersDetailsWithCode;
    if (!genCodeRes.length) {
      this.spinner.hide();
      return true;
    }

    const observables = [];
    if (messageMedium && messageMedium.length) {
      if (messageMedium.includes(AppConstants.Generic.phone)) {

        genCodeRes.forEach(userDetail => {
          if (userDetail) {
            const mesReq = new SendMessage();
            mesReq.lockUserId = userDetail.lockUserId;
            mesReq.medium = AppConstants.Generic.phone;
            mesReq.messageType = messageType;
            if (messageType != AppConstants.Generic.messageTypeArbitraryCode) {
              mesReq.lockSerialNumber = userDetail.lockSerialNumber;
            }

            observables.push(this.repo.sendMessage(mesReq));
          }
        });
      }
      if (messageMedium.includes(AppConstants.Generic.email)) {

        genCodeRes.forEach(userDetail => {
          if (userDetail) {
            const mesReq = new SendMessage();
            mesReq.lockUserId = userDetail.lockUserId;
            mesReq.medium = AppConstants.Generic.email;
            mesReq.messageType = messageType;
            if (messageType != AppConstants.Generic.messageTypeArbitraryCode) {
              mesReq.lockSerialNumber = userDetail.lockSerialNumber;
            }

            observables.push(this.repo.sendMessage(mesReq));
          }
        });
      }
    }

    if (observables.length) {
      forkJoin(observables).subscribe(
        results => {
          this.spinner.hide();
          this.appNotification.success('Successfully sent message to the user(s).');
        },
        (err) => { // TODO: handle error for individual requests
          this.spinner.hide();
          this.appNotification.error('Error! Something went wrong while sending message to the user(s).');
        });
    } else {
      this.spinner.hide();
    }
  }

  buildLockUsersDropDown() {
    if (this.lockUsersArray) {
      this.lockUsersDropDown = [];
      this.lockUsersArray.forEach((lockUser: LockUsers) => {
        if (lockUser.arbitraryKeypadCode && lockUser.arbitraryKeypadCode > 0) {
          this.lockUsersDropDown.push(
            { label: lockUser.name, value: lockUser.id }
          );
        }
      });
    }
  }
}
